.br_poi_content_manager_mtr {
    margin-top: 1rem;
}

.br_poi_content_manager_Modal {
    max-width: 1000px !important;
}

.br_poi_content_manager_lang {
    width: 50%;
}

.br_poi_content_manager_coi {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--busy--color);
    float: right;
}

.br_poi_content_manager_photo {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.br_poi_content_manager_upload {
    width: 104px;
    height: 104px;
}

.br_poi_content_manager_uploaButton {
    width: 104px;
    height: 104px !important;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
    text-align: center;
    border-radius: 4px;
    vertical-align: top;
}

.br_poi_content_manager_vicinity {
    padding-left: 2rem;
    margin-top: 1rem;
}

.marker-label {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform: translateY(23px);
    /* Adjust the translateY value as needed */
}

.br_poi_content_manager_approve {
    padding: 1.5rem;
}

.br_poi_content_manager_contribute {
    padding-top: 2.5rem;
}

.br_poi_content_manager_paragraph {
    font-size: 0.9rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}

.br_poi_content_manager_infoIcon {
    font-size: 1.2rem;
    margin-top: 0.2rem;
    margin-right: 1.5rem;
    color: var(--primary--color);
    cursor: pointer;
}

.br_poi_content_manager_listGroup {
    height: 53vh;
    overflow-y: auto;
    font-size: 0.9rem;
}

.br_poi_content_manager_listItem {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.br_poi_content_manager_span {
    font-size: 0.9rem;
    margin: 0px;
    text-align: center;
}

.br_poi_content_manager_edit {
    cursor: pointer;
    font-size: 1rem;
    color: var(--primary--color);
}

.vertical-tabs-container {
    float: left;
}

.tablinks:hover {
    background-color: #ddd;
}


/* Style for each Vertical Tab */
.vertical-tab {
    margin-bottom: 10px;
}

/* Style for the Tab Button */
.vertical-tab button {
    width: 150px;
    padding: 10px;
    text-align: left;
    background-color: #f8f9fa;
    /* Background color when not selected */
    border: 1px solid #dee2e6;
    cursor: pointer;
}

/* Style for the active Tab Button */
.tablinks:active {
    background-color: #007bff;
    /* Background color when selected */
    color: #fff;
    /* Text color when selected */
}

/* Style for the Content Area */
.vertical-tabs-content {
    float: left;
    padding: 0px 12px;
    width: 70%;
    border-left: none;
}

/* Style for each Tab Content */
.vertical-tabcontent {
    display: none;
}

/* Display the active Tab Content */
.vertical-tabcontent.active {
    display: block;
}

.approvedIcon {
    color: var(--approved--color);
    font-size: 1.3rem;
}

.rejectedIcon {
    color: var(---declined--color);
    font-size: 1.3rem;
}

.pendingIcon {
    color: var(--pending--color);
    font-size: 1.3rem;
}

.detailsIcon {
    color: var(--primary--color);
    font-size: 1.3rem;
}

.active-tab .nav-link.active {
    color: #007bff;
}

.active-tab .nav-link {
    color: #495057;
}

.tab-container {
    display: flex;
}

.tabs {
    width: 150px;
    background-color: #f2f2f2;
}

.tab {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    font-size: 0.9rem;
}

.tab.active {
    background-color: #bfdfff;
}

.contributions {
    padding: 10px;
}

.contributions ul {
    list-style-type: none;
    padding: 0;
}

.contributions li {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.contributions li:last-child {
    border-bottom: none;
}

.contributionDetailIcons {
    color: #1a73e8;
    font-size: 1rem;
    margin-right: 0.5rem;
}

.tab-title {
    display: flex;
    align-items: center;
}

.additional-icons {
    display: flex;
    margin-left: auto;
}

.additional-icons .icon {
    margin-left: 0.5rem;
}

.tabs-right-icon {
    position: absolute;
    right: 1vw;
}

.tabBackIcon {
    color: #000000;
    font-size: 1.3rem;
    margin-right: 1rem;
}

.tabApproveIcon {
    color: var(--approved--color);
    font-size: 1.3rem;
    margin-right: 1rem;
}

.tabRejectIcon {
    color: var(--declined--color);
    font-size: 1.3rem;
    margin-right: 1rem;
}

.contributionTabs .nav-link.active {
    color: #007bff;
}

.contributionTabs .nav-link {
    color: #000000;
}

.contributionTabs {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-bottom: none;
}

.poiManagerTabs .nav-link.active {
    color: #007bff;
}

.poiManagerTabs .nav-link {
    color: #000000;
}

.htmlRendered {
    font-size: 0.9rem !important;
    font-family: "Open Sans", sans-serif !important;
    line-height: 1.1rem !important;
}

.poiInfoWindowText {
    font-size: 0.9rem !important;
    font-family: "Open Sans", sans-serif !important;
}

.infoWindowTitle {
    font-weight: bold;
}

.poiTabIcon {
    font-size: 1.1rem;
    margin-bottom: 0.1rem;
    margin-right: 8px;
}

.poiModalTitle{
    font-weight: bold;
    font-size: 1.2rem;
}